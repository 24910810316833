<template>
  <div style="background: #fff;height: 100%;
">
    <div style="overflow: hidden;background: #fff;padding-top:0">
      <img class="bmbeijin" src="../../assets/img/baomingbj.png" alt />
      <img class="bmbeijin" style="display: none" src="../../assets/img/huoke.png" alt />
      <div class="shuru" style="margin-top: 0px;">
        <span class="names">
          <span>您的名字</span>
          <span class="xin">*</span>
        </span>
        <input type="text" v-model="name" placeholder="请输入姓名" />
      </div>
      <div class="shuru">
        <span class="names">
          <span>手机号码</span>
          <span class="xin">*</span>
        </span>
        <input type="tel" v-model="phone" placeholder="请输入手机号码" />
      </div>
      <div class="shuru">
        <span class="names">
          <span>企业名称</span>
          <span class="xin">*</span>
        </span>
        <input type="text" v-model="garageName" placeholder="请输入您的公司名称" />
      </div>
      <div class="baoming" @click="bindbaoming">立即报名</div>
      <div class="neirong">
        <div class="nei_a">
          已有
          <span class="nei_shu">{{bmtotal}}</span>家汽修门店报名参与
        </div>
        <div class="nei_b">-成功获取活动方案·助力门店业绩翻番-</div>
      </div>
      <div class="bott">
        <div class="bott_a" @click="tap(1)" :class="orderNavActive == 1?'active':''">
          <img class="biao_img" v-if="orderNavActive == 1" src="../../assets/img/remen_b.png" alt />
          <img v-else class="biao_img" src="../../assets/img/remen_a.png" alt />
          <span style="vertical-align: middle;">最新报名</span>
        </div>
        <div class="bott_b" @click="tap(2)" :class="orderNavActive == 2?'active':''">
          <img class="biao_img" v-if="orderNavActive == 2" src="../../assets/img/yh_b.png" alt />
          <img v-else class="biao_img" src="../../assets/img/yh_a.png" alt />
          <span style="vertical-align: middle;">关注用户</span>
        </div>
        <div class="xian"></div>
      </div>
      <div class="mian_a" v-show="orderNavActive == 1">
        <div class="bm_list" v-for="(item,indnx) in bmlist" :key="indnx">
          <div class="left immgs">
            <img class="tou_img left" :src="item.avatar" alt />
          </div>
          <div class="left tou_list">
            <div class="tou_a">
              <span class="left" style="color: #000;">{{item.name}}</span>
              <span class="right">{{item.phone}}</span>
            </div>
            <div class="tou_a">
              <span class="left">{{item.garageName}}</span>
              <span class="right">
                第
                <span style="color:red">{{bmtotal-indnx}}</span>位报名
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="mian_a" v-show="orderNavActive == 2">
        <div class="gz_list" v-for="(item,idx) in gzlist" :key="idx">
          <img class="tou_img left" :src="item.avatar" alt />
          <div class="gz_a">
            <div class>{{item.name}}</div>
            <div class="tou_a">
              <div class="right">
                No
                <span style="color:red">{{gztotal-idx}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="dgenduo">
        <van-button
          icon="plus"
          @click="bmgenduo"
          v-show="orderNavActive == 1 && bmtotal > 20 && !bmfinished"
          class="genduo"
          type="info"
        >查看更多</van-button>
        <van-button
          icon="plus"
          @click="gzgenduo"
          v-show="orderNavActive == 2 && gztotal > 20  && !gzfinished"
          class="genduo"
          type="info"
        >查看更多</van-button>
      </div>
    </div>
  </div>
</template>

<script>
import userModel from "../../api/user";
import utils from "../../assets/utils";
export default {
  data() {
    return {
      gid: "",
      garageName: "",
      name: "",
      phone: "",
      orderNavActive: 1,
      aid: this.$route.query.aid,
      userInfo: JSON.parse(localStorage.getItem("userInfo")),
      content: [],
      gzlist: [],
      bmlist: [],
      uid: "",
      gzpage: 0,
      gzsize: 50,
      gztotal: null,
      gzfinished: false,
      bmfinished: false,
      bmpage: 0,
      bmsize: 20,
      bmtotal: null,
      list: {}
    };
  },
  methods: {},
  created() {
    var ycjisok = utils.getUrlKey("ycjisok");
    sessionStorage.setItem("ycjisok", ycjisok);
    if (localStorage.getItem("hdtoken")) {
      if (!localStorage.getItem("baoming")) {
        localStorage.setItem("baoming", 1);
      }
      if (localStorage.getItem("baoming") == 1) {
        sessionStorage.setItem("tobaoming", true);
        localStorage.setItem("baoming", 2);
        var appid = "wx406b62706ded5aa8";
        const redirect_uri = encodeURIComponent(
          "https://marketing.car-posthouse.cn/auth"
        );
        window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`;
      }

      this.getConfig();
      this.tjguanzhu();
      this.baominlist();
      //   var isok = localStorage.getItem("isok");
      //   if (isok == "true") {
      //     setTimeout(res => {
      //       this.$router.go(0);
      //     }, 100);
      //     localStorage.removeItem("isok");
      //   }
    } else {
      sessionStorage.setItem("tobaoming", true);
      localStorage.setItem("baoming", 2);
      var appid = "wx406b62706ded5aa8";
      const redirect_uri = encodeURIComponent(
        "https://marketing.car-posthouse.cn/auth"
      );
      // window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`;
    }
    // this.userInfo.userId 3418
    // this.baominlist();
  },
  methods: {
    tjguanzhu() {
      userModel.follow({ uid: this.userInfo.userId }).then(res => {
        if (res.code == 200) {
          this.gzyonghulist();
        }
      });
    },
    tap(i) {
      this.orderNavActive = i;
    },
    gzyonghulist() {
      var data = {
        page: this.gzpage,
        size: this.gzsize
      };
      userModel.guanzhulist({ data }).then(res => {
        if (res.code == 200) {
          let rows = res.data.content;
          console.log(res);
          this.loading = false;
          this.gztotal = res.data.totalElements;
          if (rows.length > 0) {
            rows.forEach((item, index) => {
              if (item.name) {
                item.name = item.name.substr(0, 4) + "**";
              }
            });
          }
          this.gzlist = this.gzlist.concat(rows);
          if (this.gzlist.length >= this.gztotal) {
            this.gzfinished = true;
          }
        }
      });
    },
    //查看更多报名
    bmgenduo() {
      this.bmpage++;
      this.baominlist();
    },
    //查看更多关注
    gzgenduo() {
      this.gzpage++;
      this.gzyonghulist();
    },
    baominlist() {
      var data = {
        page: this.bmpage,
        size: this.bmsize
      };
      userModel.baominglist({ data }).then(res => {
        if (res.code == 200) {
          let rows = res.data.content;
          console.log(res);
          this.loading = false;
          this.bmtotal = res.data.totalElements;
          if (rows.length > 0) {
            sessionStorage.getItem("ycjisok");
            if (sessionStorage.getItem("ycjisok") == "true") {
            } else {
              rows.forEach((item, index) => {
                if (item.name) {
                  item.name = item.name.substr(0, 1) + "**";
                }
                if (item.garageName) {
                  item.garageName =
                    item.garageName.substr(0, 2) +
                    "**" +
                    item.garageName.substring(item.garageName.length - 2);
                  console.log(item.garageName);
                }
                if (item.phone) {
                  item.phone =
                    item.phone.substr(0, 3) +
                    "**" +
                    item.phone.substring(item.phone.length - 4);
                }
              });
            }
          }
          this.bmlist = this.bmlist.concat(rows);
          if (this.bmlist.length >= this.gztotal) {
            this.bmfinished = true;
          }
        }
      });
    },
    bindbaoming() {
      if (this.name == "") {
        this.$toast("请输入您的姓名！");
        return;
      }
      if (this.phone == "") {
        this.$toast("请输入您的手机号码！");
        return;
      } else if (!/^1[23456789]\d{9}$/.test(this.phone)) {
        this.$toast("手机号码格式错误!");
        return;
      }
      if (this.garageName == "") {
        this.$toast("请输入您的公司名称！");
        return;
      }
      var data = {
        avatar: this.userInfo.avatar, //微信头像
        garageName: this.garageName, //企业名称
        name: this.name, //姓名
        phone: this.phone, //手机号码
        uid: this.userInfo.userId //用户id
      };
      userModel.baomingup(data).then(res => {
        if (res.code == 200) {
          this.$toast.success("报名成功！");
          this.baominlist();
        }
      });
    },
    getConfig() {
      let that = this;
      let userAgent = navigator.userAgent
      let shareUrl ='';
      if (userAgent.includes('iPhone') || userAgent.includes('iPad')) {
          shareUrl = sessionStorage.getItem('originUrl')
      }else {
          shareUrl = location.href
      }
      userModel
        .config({
          url: shareUrl,
          gid: 208
        })
        .then(res => {
          if (res.code == 200) {
            console.log(res);
            wx.config({
              debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
              appId: "wx406b62706ded5aa8", // 必填，公众号的唯一标识
              timestamp: res.data.timestamp, // 必填，生成签名的时间戳
              nonceStr: res.data.noncestr, // 必填，生成签名的随机串
              signature: res.data.signature, // 必填，签名
              jsApiList: [
                "openLocation",
                "updateAppMessageShareData",
                "updateTimelineShareData",
              ] // 必填，需要使用的JS接口列表
            });
            if (sessionStorage.getItem("ycjisok") == "true") {
              var link =
                "https://marketing.car-posthouse.cn/apply?ycjisok=true";
              var desc = that.userInfo.realName + "私人权限，切勿分享";
              var title = "【驿车驹私人权限】引流·定制专属活动方案";
            } else {
              var title = "【驿车驹】引流·锁客·练团队·定制专属活动方案";
              var link = "https://marketing.car-posthouse.cn/apply";
              var desc =
                that.userInfo.realName + "已参与活动，邀你一起报名参加活动";
            }

            var url = window.location.href;
            var imgUrl =
              "https://marketing.car-posthouse.cn/assets/img/huoke.44eac7fe.png";
            //分享给朋友
            wx.ready(() => {
              wx.updateAppMessageShareData({
                title: title,
                desc: desc,
                link: link,
                imgUrl: imgUrl,
                success: function() {}
              });
            });
          } else {
            this.$toast(res.data.msg);
          }
        });
    },
    back() {
      this.$router.go(-1);
    }
  }
};
</script>
<style  lang="less" scoped>
.shuru {
  border: 1px solid #dcdcdc;
  height: 50px;
  line-height: 50px;
  margin: 12px 15px;
  border-radius: 4px;
  font-size: 16px;
  overflow: hidden;
}
input {
  display: inline-block;
  border: none;
  // width: 65%;
  line-height: 42px;
  margin-left: 10px;
}
.names {
  line-height: 50px;

  display: inline-block;
  background: #f5f5f5;
  padding: 0 30px 0 10px;
}
.xin {
  color: red;
  margin-left: 5px;
}
.baoming {
  width: 70%;
  height: 56px;
  background: #ee0a24;
  margin: auto;
  color: #fff;
  border-radius: 30px;
  line-height: 56px;
  text-align: center;
  margin-top: 30px;
  font-size: 22px;
}
.immgs {
  width: 14%;
}
.neirong {
  border: 1px solid #dcdcdc4d;
  padding: 20px 0;
  margin: 45px 12px 0 12px;
  text-align: center;
  font-size: 16px;
}
.nei_shu {
  color: red;
  font-size: 24px;
}
.nei_a {
  color: #666;
}
.nei_b {
  color: red;
  margin-top: 15px;
}
.bott {
  overflow: hidden;
  margin: 40px 12px 0px 12px;
  line-height: 45px;
  position: relative;
  height: 45px;
}
.xian {
  position: absolute;
  bottom: 0;
  width: 100%;
  //   margin-bottom: 0.5px;
  border-bottom: 1px solid #999;
  left: 0;
  //  z-index: -1;
}
.bott_a {
  position: absolute;
  top: 0;
  left: 0;
  width: 48%;
  margin-left: 2%;
  text-align: center;
  color: #666;
}
.bott_b {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 99;
  width: 48%;
  margin-right: 2%;
  text-align: center;
  color: #666;
}
.active {
  border-radius: 4px 4px 0 0;
  border: 1px solid #999;
  border-bottom: none;
  background: #fff;
  z-index: 99;
  color: #fc0e05;
}
.mian_a {
  overflow: hidden;
  margin: 10px 12px 0 12px;
}
.bm_list {
  overflow: hidden;
  padding: 10px 0;
  border-bottom: 1px solid #dcdcdc;
}
.gz_list {
  overflow: hidden;
  width: 50%;
  float: left;
  padding: 10px 0;
  border-bottom: 1px solid #dcdcdc;
}
.tou_img {
  width: 45px;
  height: 45px;
  border-radius: 5px;
}
.tou_list {
  overflow: hidden;
  width: 83%;
  margin-left: 2%;
}
.tou_a {
  overflow: hidden;
  color: #999;
}
.gz_a {
  margin-left: 10px;
  float: left;
  width: 60%;
}
.biao_img {
  width: 25px;
  vertical-align: middle;
  margin-right: 10px;
  margin-bottom: 2px;
}
.genduo {
  font-size: 16px;
  width: 90%;
  margin: auto;

  line-height: 40px;
  background: #2894ff;
  border: 1px solid #2894ff;
}
.dgenduo {
  margin-top: 20px;
  text-align: center;
}
.bmbeijin {
  width: 100%;
}
</style>